import React, { Fragment, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Navbar, Nav } from "react-bootstrap";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";

// Components
import Input from "components/Form/Input/Input";
import Button from "components/Form/Button/Button";
import Image from "components/Image/Image";

// Constants
import { ROUTES, CLOUDINARY_BASE_URL } from "constants/Constants";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import History from "utils/History";

// Context
import GlobalContext from "contexts/Global.context";

// SCSS
import "./Header.scss";

const Header = () => {
  const { userDetails, isLoggedIn, logout, variantDetails } = useContext(GlobalContext);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [keyword, setKeyword] = useState('');
  const toogleSearchBox = () => setShowSearchBox(!showSearchBox);
  
  const LOGO = variantDetails?.header_logo_url || `${variantDetails?.assets_url}/'header_logo.svg'}`;
  const PROFILE_LOGO = `${variantDetails?.assets_url}/header_logo_thumb.svg`;
  const marketPlaceURL = variantDetails?.marketplace_url || "https://beyondphysician.com";

  const setSearchText = (e: any) => {
    setKeyword(e.target.value);
  };

  const doSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13 || event.which === 13 || event.key === 'Enter') {
      event.preventDefault();
      History.push(`${ROUTES.SEARCH}?keyword=${keyword}`);
    }
  }

  const clippedPhoto = (src: String) => {
    return `${CLOUDINARY_BASE_URL}/c_thumb,w_34,h_34,g_face,f_auto/s3/${src.substr(src.lastIndexOf('/') + 1)}`;
  };

  return (
    <Fragment>
      <div className="headerWrap">
        <Container>
          <Row>
            <Col lg={6} xs={12} className="p-static">
              <Navbar expand="lg" className="mainMenu" collapseOnSelect={true}>
                <Navbar.Brand
                  className="miaBranding"
                  as={Link}
                  to={ROUTES.HOME}
                >
                  <Image
                    className="mainLogo"
                    src={LOGO}
                    title={variantDetails?.site_short_title}
                  />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="mr-auto">
                    <Nav.Link eventKey={1} as={Link} to={ROUTES.COURSES}>
                      Courses
                    </Nav.Link>
                    <Nav.Link eventKey={2} as={Link} to={ROUTES.CERTIFICATIONS}>
                      Credentials
                    </Nav.Link>
                    {!variantDetails?.about_page_url ? <Nav.Link eventKey={3} as={Link} to={ROUTES.ABOUT}>About</Nav.Link>
                  : <a
                    href={variantDetails?.about_page_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    About
                  </a>}
                    <Nav.Link eventKey={4} as={Link} to={ROUTES.SUPPORT}>
                      Contact
                    </Nav.Link>
                    <a
                      href={marketPlaceURL}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="nav-link"
                    >
                      Marketplace
                    </a>

                    {isLoggedIn ? (
                      <div></div>
                    ) : (
                      <div className="headerButtons d-xl-none d-lg-none d-md-flex">
                        <Nav.Link
                          eventKey={4}
                          as={Link}
                          to={ROUTES.LOGIN}
                          className="blank-btn"
                        >
                          Login
                        </Nav.Link>
                        <Nav.Link
                          eventKey={5}
                          as={Link}
                          to={ROUTES.REGISTER}
                          className="ghostBtn"
                        >
                          Register
                        </Nav.Link>
                      </div>
                    )}
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </Col>
            <Col lg={6} xs="auto">
              <div className="headerRight">
                <div className="searchOuter d-xl-flex d-lg-flex d-none">
                  <Input
                    onKeyPress={doSearch}
                    onChange={setSearchText}
                    className="transparent"
                    placeholder="Search"
                  />
                  <FontAwesomeIcon icon={faSearch} className="searchIcon" />
                </div>
                <div className="searchOuter d-xl-none d-lg-none d-md-block">
                  <FontAwesomeIcon
                    icon={faSearch}
                    className="searchIcon"
                    onClick={toogleSearchBox}
                  />
                  {showSearchBox ? (
                    <div className="searchInputBox">
                      <Input
                        className="transparent"
                        onKeyPress={doSearch}
                        onChange={setSearchText}
                        placeholder="Search"
                      />
                    </div>
                  ) : null}
                </div>
                {isLoggedIn ? (
                  <Fragment>
                    <div className="profileInfo">
                      <div className="profileImg">
                        {userDetails.photo ? (
                          <img
                            className="profilePhoto"
                            src={clippedPhoto(userDetails.photo)}
                            alt="profile"
                          />
                        ) : (
                          <Image src={PROFILE_LOGO} title="profile" />
                        )}
                      </div>
                      <div className="proifleName">
                        <DropdownButton
                          alignRight
                          title={userDetails.user_name || ""}
                          id="dropdown-menu-align-right"
                          className="dropdownButton"
                        >
                          <Dropdown.Item
                            eventKey="option-2"
                            as={Link}
                            to={ROUTES.DASHBOARD}
                          >
                            Dashboard
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="option-3"
                            onClick={() => logout()}
                            as={Link}
                            to={ROUTES.HOME}
                          >
                            Logout
                          </Dropdown.Item>
                        </DropdownButton>
                      </div>
                    </div>
                  </Fragment>
                ) : (
                  <div className="headerButtons d-xl-block d-lg-block d-none">
                    <Link to={ROUTES.LOGIN}>
                      <Button label="Login" type="blank" />
                    </Link>
                    <Link to={ROUTES.REGISTER}>
                      <Button label="Register" type="ghostBtn" />
                    </Link>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default React.memo(Header);
