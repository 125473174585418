import React, { Fragment, useContext } from "react";
import { Row, Col } from "react-bootstrap";

// Components
import Course from "components/Course/Course";
import Certificate from "components/Certificate/Certificate";

import GlobalContext from "contexts/Global.context";

// SVGs
import NoCourse from "assets/imgs/courses/no-course.png";

// SCSS
import styles from "./DashboardContent.module.scss";

// Context
import DashboardContext from "../../contexts/Dashboard.context";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

const DashboardContent = ({ activeTab }) => {
  const {
    pendingCourses,
    completedCourses,
    completedQuizzes,
    pendingQuizzes,
    isLoading,
  } = useContext(DashboardContext);

  const { variantDetails } = useContext(GlobalContext);

  const marketPlaceURL = variantDetails?.marketplace_url || process.env.REACT_APP_MARKETPLACE_URL || "https://beyondphysician.com";

  const checkAlmostDone = (course: any) => {
    const { completed_modules, total_modules } = course;
    if ((parseInt(completed_modules) * 100) / parseInt(total_modules) >= 75) {
      return true;
    }
    return false;
  }

  return (
    <Fragment>
      {activeTab === 3 && <Row>
        <Col>
          <div className={styles.gridListing}>
            <div className={styles.heading}>Still Pending</div>
            <div className={styles.list}>
              <Row>
                {!isLoading ? pendingCourses.map((course, index) => {
                  return (
                    <Col xl="4" lg="4" md="6" xs="12" key={index}>
                      <Course
                        id={course.id}
                        image={course.banner}
                        category={course.category}
                        title={course.title}
                        description={course.description}
                        count={course.registrations}
                        isOffsite={course.is_offsite_affiliate}
                        externalLink={course.external_link}
                      />
                      {checkAlmostDone(course) && (
                        <div className={styles.status}>
                          <span>
                            <FontAwesomeIcon icon={faCheckCircle} />
                            Almost Done
                          </span>
                        </div>
                      )}
                    </Col>
                  );
                }) : <div className="loader"><span></span><span></span></div>}
              </Row>
            </div>
          </div>
        </Col>
      </Row>}
      {activeTab === 1 && <Row>
        <Col>
          <div className={styles.gridListing}>
            <div className={styles.heading}>Recently Finished</div>
            <div className={styles.list}>
              <Row>
                {!isLoading ? completedCourses.map((course, index) => {
                  return (
                    <Col xl="4" lg="4" md="6" xs="12" key={index}>
                      <Course
                        id={course.id}
                        image={course.banner}
                        category={course.category}
                        title={course.title}
                        description={course.description}
                        count={course.registrations}
                        isCompleted={true}
                        isOffsite={course.is_offsite_affiliate}
                        externalLink={course.external_link}
                      />
                    </Col>
                  );
                }) : <div className="loader"><span></span><span></span></div>}
                {!isLoading && completedCourses.length === 0 && <Col xl="4" lg="4" md="6" xs="12">
                  <Course
                    image={NoCourse}
                    category=""
                    title="No Courses Found."
                    description="Each journey starts with a single step. Select a course and begin your journey."
                  />
                </Col>}
              </Row>
            </div>
          </div>
        </Col>
      </Row>}
      {activeTab === 0 && <Row>
        <Col>
          <div className={`${styles.gridListing} ${styles.noBorder}`}>
            <div className={styles.heading}>Completed Credentials
              <div className={styles.description}>All credentials earned on here in the Beyond Physician Academy will automatically appear on your profile in the <a href={marketPlaceURL} rel="noopener noreferrer" target="_blank">Beyond Physician Marketplace</a></div>
            </div>
            <div className={styles.list}>
              <Row>
                {!isLoading ? completedQuizzes.map((quiz, index) => {
                  return (
                    <Col xl="4" lg="4" md="6" xs="12" key={index}>
                      <Certificate
                        id={quiz.id}
                        image={quiz.banner}
                        category={quiz.category}
                        title={quiz.title}
                        description={quiz.description}
                        registrations={quiz.registrations}
                        downloadable={true}
                      />
                    </Col>
                  )
                }) : <div className="loader"><span></span><span></span></div>}
              </Row>
            </div>
          </div>
        </Col>
      </Row>}
      {activeTab === 2 && <Row>
        <Col>
          <div className={`${styles.gridListing} ${styles.noBorder}`}>
            <div className={styles.heading}>Pending Credentials</div>
            <div className={styles.list}>
              <Row>
                {!isLoading ? pendingQuizzes.map((quiz, index) => {
                  return (
                    <Col xl="4" lg="4" md="6" xs="12" key={index}>
                      <Certificate
                        id={quiz.id}
                        image={quiz.banner}
                        category={quiz.category}
                        title={quiz.title}
                        description={quiz.description}
                        registrations={quiz.registrations}
                      />
                    </Col>
                  )
                }) : <div className="loader"><span></span><span></span></div>}
              </Row>
            </div>
          </div>
        </Col>
      </Row>}
    </Fragment>
  );
};

export default React.memo(DashboardContent);
