import React from 'react';

// Types
import { Option } from 'interfaces/Quiz.types';

import Radio from "components/Form/Radio/Radio";
import Button from "components/Form/Button/Button";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

// styles
import styles from './QuestionAndOptions.module.scss';

interface Props {
	question: string;
	options: Option[],
	answer?: string;
	isResult?: boolean;
	setAnswer?: (id: string) => void;
	image?: string;
	disabledSubmit?: boolean;
	submitAnswer?: () => void;
}

const getClassName = (isResult: boolean, option: Option, answer?: string) => {
	if (isResult && option.id === answer) {
		if (option.is_correct) {
			return styles.correct;
		}
		return styles.wrong;
	}
	return '';
}

const getIcon = (isResult: boolean, option: Option, answer?: string) => {
	if (isResult) {
		if (option.id === answer && !option.is_correct) {
			return styles.wrongIcon;
		}
		if (option.is_correct) {
			return styles.correctIcon;
		}
		return '';
	}
	return '';
}

const QuestionAndOptions: React.FC<Props> = ({ question, options, answer, setAnswer, image, isResult = false, submitAnswer, disabledSubmit }) => {
	return (
		<div className={styles.questionWrapper}>
			<div className={`${styles.quizName} ${styles.contentWidth} ${isResult ? styles.resultQuestion : ''}`}>
	      {question?.replace('�', '\'')}
	    </div>
	    {image ? <div className={styles.imgWrapper}>
        <img src={image} alt="question-banner" />
      </div> : null}
	    <div className={`${styles.quizOptions} ${styles.contentWidth}`}>
	    	{!isResult && <div className={styles.quizHeading}>
	        Select an answer from the multiple choices below.
	      </div>}
	      <div className={styles.optionsList}>
	        {options?.map((option, index) => {
	          return (
	            <div key={index} className={`${isResult ? styles.result : ''} ${getClassName(isResult, option, answer)} ${getIcon(isResult, option, answer)}`}>
	              <Radio
	                isSelected={answer === option.id}
	                label={option.option}
	                name={question}
	                id={option.id}
	                onChange={() => !isResult && setAnswer(option.id)}
	                className={`lgCheckbox${isResult ? ' highlightBlack' : ''}`}
	              />
	              {isResult && option.id === answer && !option.is_correct ?
	              	<span className={styles.wrongIcon}>
                    <FontAwesomeIcon icon={faTimesCircle} />
                  </span>
	              : null}
	              {isResult && option.is_correct ?
	              	<span className={styles.rightIcon}>
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </span>
	              : null}
	            </div>
	          )
	        })}
	      </div>
	      {!isResult && question && <div className={styles.btnWrapper}>
          <Button onClick={submitAnswer} disabled={disabledSubmit} label="Submit" type="large branding" />
        </div>}
	    </div>
	  </div>
	);
};

export default React.memo(QuestionAndOptions);