import React, { useState, useEffect, useCallback, useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

import History from "utils/History";

// Components
import CertificationsHeader from "components/CertificationsHeader/CertificationsHeader";
import Slider from "pages/Home/components/Slider/Slider";
import Course from "components/Course/Course";
import Select from "components/Form/Select/Select";

import { CourseList } from "interfaces/CourseList.types";

import { fetchTopCourses } from "services/CourseService";

import GlobalContext from "contexts/Global.context";

// Constants
import {
  COURSES_PAGE_HEADING,
  INDUSTRIES,
  COST_OPTIONS,
  CONTENT_TYPE_OPTIONS,
} from "constants/Constants";

// Text
import COPY_OVERRIDES from "config/CopyOverrides";

//SCSS;
import styles from "./Courses.module.scss";

const AVAILABLE_COURSES = 100;
const ORDER_BY = "registrations";

const Courses = () => {
  const { categories, isLoggedIn, variantDetails } = useContext(GlobalContext);
  const categoryOptions = isLoggedIn ? [{ label: 'Recommended For Me', value: 'forMe' }, ...categories] : categories;
  const { search, pathname } = useLocation();
  const CourseImg = `${variantDetails?.assets_url}/course_img.svg`;
  const {
    category: queryCategory,
    cost: queryCost,
    keyword,
    content_type: queryContentType
  } = queryString.parse(search);
  const [courses, setCourses] = useState<CourseList[]>([]);
  const [category, setCategory] = useState<string>(
    queryCategory ? decodeURIComponent(queryCategory.toString()) : ""
  );
  const [cost, setCost] = useState<string>(
    queryCost ? decodeURIComponent(queryCost.toString()) : ""
  );
  const [content_type, setContentType] = useState<string>(
    queryContentType ? decodeURIComponent(queryContentType.toString()) : ""
  );
  const [industry, setIndustry] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);

  const updateQueryString = useCallback(() => {
    const query = [];
    if (keyword) {
      query.push(`keyword=${keyword}`);
    }
    if (cost) {
      query.push(`cost=${cost}`);
    }
    if (category) {
      query.push(`category=${category}`);
    }
    if (content_type) {
      query.push(`content_type=${content_type}`);
    }
    if (query.length) {
      History.push(`${pathname}?${query.join("&")}`);
      return;
    }
    History.push(pathname);
  }, [category, cost, keyword, pathname, content_type]);

  const getCourses = useCallback(async () => {
    setLoading(true);
    updateQueryString();
    const { courses, total, error } = await fetchTopCourses(
      category,
      AVAILABLE_COURSES,
      ORDER_BY,
      keyword,
      { industry, cost, content_type }
    );
    if (!error) {
      setCourses(courses);
      setTotalCount(total);
    }
    setLoading(false);
  }, [category, industry, cost, keyword, content_type, updateQueryString]);

  useEffect(() => {
    setCourses([]);
    setTotalCount(0);
    getCourses();
  }, [getCourses, category, industry, cost, content_type]);

  const handleCategoryChange = (val: string) => {
    setCategory(val);
  };

  const handleContentTypeChange = (val: string) => {
    setContentType(val);
  };

  return (
    <>
      <Helmet>
        <title>{variantDetails?.site_title} | Courses</title>
      </Helmet>
      <CertificationsHeader
        header={COPY_OVERRIDES?.courses?.header || COURSES_PAGE_HEADING}
        image={CourseImg}
        pageContent="Our expert-led courses are divided into instructional and training levels. Instructional courses give basic overviews of topics, while training courses offer advanced knowledge and detailed instructions on leveraging skills. Training courses also include quizzes that offer certifications, which can be used to unlock gigs, events, and more."
      />
      <div className={styles.coursesWapper}>
        <Container>
          <Row>
            <Col>
              <div className={styles.sectionHeading}>Available Courses</div>
            </Col>
          </Row>

          <div className={styles.tabsParent}>
            <div className={styles.filterSection}>
              <Row className="align-items-center">
                <Col sm="auto">
                  <div className={styles.filterLabel}>Filter By</div>
                </Col>
                <Col sm="auto">
                  <Select
                    id="category"
                    name="category"
                    onChange={(e) => handleCategoryChange(e.target.value)}
                    selectItems={categoryOptions}
                    value={category}
                    placeholder="Select Category"
                  />
                </Col>
                <Col sm="auto">
                  <Select
                    id="content_type"
                    name="content_type"
                    onChange={(e) => handleContentTypeChange(e.target.value)}
                    selectItems={CONTENT_TYPE_OPTIONS}
                    value={content_type}
                    placeholder="Select Content Type"
                  />
                </Col>
                {!variantDetails?.site_short_title.includes(
                  "BeyondPhysician"
                ) && (
                  <Col sm="auto">
                    <Select
                      id="industry"
                      name="industry"
                      onChange={(e) => setIndustry(e.target.value)}
                      selectItems={INDUSTRIES}
                      value={industry}
                      placeholder="Select Industry"
                    />
                  </Col>
                )}
                <Col sm="auto">
                  <Select
                    id="cost"
                    name="cost"
                    onChange={(e) => setCost(e.target.value)}
                    selectItems={COST_OPTIONS}
                    value={cost}
                    placeholder="Select Cost"
                  />
                </Col>
              </Row>
            </div>
            <div className={styles.tabContent}>
              <div className="tab-item-wrapper">
                <Row className={styles.tabRow}>
                  {!loading &&
                    !!courses.length &&
                    courses.map((course, key) => {
                      return (
                        <Col key={key} xl="3" lg="3" md="6" xs="12">
                          <Course
                            id={course.id}
                            image={course.banner}
                            category={course.category}
                            title={course.title}
                            description={course.description}
                            isOffsite={course.is_offsite_affiliate}
                            externalLink={course.external_link}
                          />
                        </Col>
                      );
                    })}
                  {loading && (
                    <div className="loader">
                      <span></span>
                      <span></span>
                    </div>
                  )}
                </Row>
              </div>
            </div>
            {!loading && (
              <span className={styles.resultsCount}>{totalCount} Results</span>
            )}
          </div>
        </Container>
      </div>
      <Slider />
    </>
  );
};

export default React.memo(Courses);
