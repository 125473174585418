import React, { useState, useEffect, useCallback, useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

import History from "utils/History";

// Components
import CertificationsHeader from "components/CertificationsHeader/CertificationsHeader";
import Course from "components/Course/Course";
import Certificate from "components/Certificate/Certificate";
import Select from "components/Form/Select/Select";

import { CourseList } from "interfaces/CourseList.types";
import { QuizList } from "interfaces/QuizList.types";

import { fetchTopCourses } from "services/CourseService";
import { fetchTopQuizzes } from "services/QuizService";

import GlobalContext from "contexts/Global.context";

//SCSS;
import styles from "./Search.module.scss";

const AVAILABLE_RECORDS = 100;
const ORDER_BY = "registrations";

const Search = () => {
  const { categories, variantDetails } = useContext(GlobalContext);
  const marketPlaceURL = variantDetails?.marketplace_url || process.env.REACT_APP_MARKETPLACE_URL || "https://beyondphysician.com";
  const { search, pathname } = useLocation();
  const { category: queryCategory, keyword } = queryString.parse(search);
  const [courses, setCourses] = useState<CourseList[]>([]);
  const [certifications, setCertifications] = useState<QuizList[]>([]);
  const [category, setCategory] = useState<string>(
    queryCategory ? decodeURIComponent(queryCategory.toString()) : ""
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [totalCourses, setTotalCourses] = useState<number>(0);
  const [totalCerts, setTotalCerts] = useState<number>(0);
  const GraduateImg = `${variantDetails?.assets_url}/graduation.svg`;

  const updateQueryString = useCallback(() => {
    const query = [];
    if (keyword) {
      query.push(`keyword=${keyword}`);
    }
    if (category) {
      query.push(`category=${category}`);
    }
    if (query.length) {
      History.push(`${pathname}?${query.join("&")}`);
      return;
    }
    History.push(pathname);
  }, [category, keyword, pathname]);

  const getResults = useCallback(async () => {
    setLoading(true);
    updateQueryString();
    const { courses, total, error } = await fetchTopCourses(
      category,
      AVAILABLE_RECORDS,
      ORDER_BY,
      keyword
    );
    if (!error) {
      setCourses(courses);
      setTotalCourses(total);
    }
    const {
      quizzes,
      total: quizTotal,
      error: quizError,
    } = await fetchTopQuizzes(AVAILABLE_RECORDS, ORDER_BY, category, keyword);
    if (!quizError) {
      setCertifications(quizzes);
      setTotalCerts(quizTotal);
    }
    setLoading(false);
  }, [category, keyword, updateQueryString]);

  useEffect(() => {
    setCourses([]);
    setTotalCourses(0);
    setTotalCerts(0);
    getResults();
  }, [getResults]);

  return (
    <>
      <Helmet>
        <title>{variantDetails?.site_title} | Search</title>
      </Helmet>
      <CertificationsHeader
        header="Learn new skills and earn top credentials"
        image={GraduateImg}
        pageContent={`Courses and credentials are available from both Beyond Physician and our community of expert teachers. All courses and instructors have been vetted by our team and offer the best perspective on alternative education topics. Credentials are certificates of completion to prove your demonstrated knowledge which can unlock advanced courses, side gigs (in our <a href=${marketPlaceURL} target='_blank" rel='noopener noreferrer'>Marketplace</a>), events, and more.`}
      />
      <Container>
        <div className={styles.filterSection}>
          <Row className="align-items-center">
            <Col sm="auto">
              <div className={styles.filterLabel}>Filter By</div>
            </Col>
            <Col sm="auto" className={styles.categoryFilter}>
              <Select
                id="category"
                name="category"
                onChange={(e) => setCategory(e.target.value)}
                selectItems={categories}
                value={category}
                placeholder="Select Category"
              />
            </Col>
          </Row>
        </div>
      </Container>
      <div className={styles.resultsWapper}>
        <Container>
          <Row>
            <Col>
              <div className={styles.sectionHeading}>Available Courses</div>
            </Col>
          </Row>

          <div className={styles.tabsParent}>
            <div className={styles.tabContent}>
              <div className="tab-item-wrapper">
                <Row className={styles.tabRow}>
                  {!loading &&
                    !!courses.length &&
                    courses.map((course, key) => {
                      return (
                        <Col key={key} xl="3" lg="3" md="6" xs="12">
                          <Course
                            id={course.id}
                            image={course.banner}
                            category={course.category}
                            title={course.title}
                            description={course.description}
                            isOffsite={course.is_offsite_affiliate}
                            externalLink={course.external_link}
                          />
                        </Col>
                      );
                    })}
                  {loading && (
                    <div className="loader">
                      <span></span>
                      <span></span>
                    </div>
                  )}
                </Row>
              </div>
            </div>
            {!loading && (
              <span className={styles.resultsCount}>
                {totalCourses} Results
              </span>
            )}
          </div>
        </Container>
      </div>
      <div className={styles.resultsWapper}>
        <Container>
          <Row>
            <Col>
              <div className={styles.sectionHeading}>
                Available Credentials
              </div>
            </Col>
          </Row>

          <div className={styles.tabsParent}>
            <div className={styles.tabContent}>
              <div className="tab-item-wrapper">
                <Row className={styles.tabRow}>
                  {!loading &&
                    !!certifications.length &&
                    certifications.map((certification, key) => {
                      return (
                        <Col key={key} xl="3" lg="3" md="6" xs="12">
                          <Certificate
                            id={certification.id}
                            image={certification.banner}
                            category={certification.category}
                            title={certification.title}
                            description={certification.description}
                            registrations={certification.registrations}
                          />
                        </Col>
                      );
                    })}
                  {loading && (
                    <div className="loader">
                      <span></span>
                      <span></span>
                    </div>
                  )}
                </Row>
              </div>
            </div>
            {!loading && (
              <span className={styles.resultsCount}>{totalCerts} Results</span>
            )}
          </div>
        </Container>
      </div>
    </>
  );
};

export default React.memo(Search);
