import React, { useEffect, useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
// Components
// import Button from 'components/Form/Button/Button';
// import Input from 'components/Form/Input/Input';
//import Select from 'components/Form/Select/Select';
import GlobalContext from "contexts/Global.context";

// SCSS
import styles from './Support.module.scss';

const Support = () => {
  const { variantDetails } = useContext(GlobalContext);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = variantDetails?.support_script_url;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    }
  }, [variantDetails?.support_script_url]);

  return (
    <div className={styles.supportPage}>
      <Helmet>
        <title>{variantDetails?.site_title} | Support</title>
      </Helmet>
      <Container>
        <Row>
          <Col md={12}>
            <div className="_form_158"></div>
            <div className="_form_9"></div>
            <div className="_form_43"></div>
            <div className={`_form_${variantDetails?.support_script_url?.split('id=')?.[1]}`}></div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Support;
