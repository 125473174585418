import React, { useEffect, useState } from "react";
import { Router } from "react-router-dom";
import { jwtDecode as jwt } from 'jwt-decode';
import history from "./utils/History";

// Context
import GlobalState from "./contexts/Global.state";

import { App } from "./App";
import { Loader } from "./components/Loader/Loader";
import { validateVariantService } from "./services/CommonService";
import { NotFoundPage } from "./pages/NotFoundPage/NotFoundPage";

export const HostValidationWrapper = () => {
  const [validating, setValidating] = useState<boolean>(true);
  const [variantDetails, setVariantDetails] = useState<any>(null);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    const validateVariant = async () => {
      if (localStorage.getItem('token')) {
        const userDetails: any = jwt(localStorage.getItem('token'));
        if (userDetails.variant_host && !window.location.href.includes(userDetails.variant_host)) {
          window.location.href = `https://${userDetails.variant_host}/dashboard`;
          return;
        }
      }
      const variantResponse = await validateVariantService();
      if (variantResponse.variantDetails) {
        setVariantDetails(variantResponse.variantDetails);
        (window as any).SITE_SHORT_TITLE =
          variantResponse.variantDetails.site_short_title;
        if (
          variantResponse.variantDetails.theme &&
          typeof variantResponse.variantDetails.theme === "object"
        ) {
          const root = document.documentElement;
          Object.keys(variantResponse.variantDetails.theme).forEach((key) => {
            root.style.setProperty(
              `--${key}`,
              variantResponse.variantDetails.theme[key],
            );
          });
        }
      } else {
        setError(true);
      }
      setValidating(false);
    };
    validateVariant();
  }, []);

  return (
    <>
      {validating && (
        <div
          style={{
            height: "100vh",
            width: "100%",
            marginLeft: "50%",
            marginTop: "20%",
          }}
        >
          <Loader />
        </div>
      )}
      {error && <NotFoundPage />}
      {variantDetails && (
        <Router history={history}>
          <GlobalState variantDetails={variantDetails}>
            <App />
          </GlobalState>
        </Router>
      )}
    </>
  );
};
