import { API_ENDPOINTS, API_METHODS, DEFAULT_API_HEADER } from '../constants/Constants';


export const fetchTopCourses = async (category?: string, limit?: number, orderBy?: string, keyword?: any, others?: any) => {
  try {
    let qs = `?orderBy=${orderBy || 'registrations'}`;
    if (category) {
      qs += `&category=${category}`;
    }
    if (limit) {
      qs += `&limit=${limit}`;
    }
    if (keyword) {
      qs += `&title=${keyword}`;
    }
    if (others) {
      if (others.industry) {
        qs += `&industry=${others.industry}`;
      }
      if (others.cost) {
        qs += `&cost=${others.cost}`;
      }
      if (others.content_type) {
        qs += `&content_type=${others.content_type}`;
      }
    }
    const response = await fetch(`${API_ENDPOINTS.FETCH_TOP_COURSES}${qs}`, {
      method: API_METHODS.GET,
      headers: {
        ...DEFAULT_API_HEADER,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    if (!response.ok) {
      return {
        error: await response.clone().text(),
      };
    } else {
      const { courses, total } = await response.json();
      return { courses, total };
    }
  } catch (err) {
    return { error: err.message };
  }
};

export const fetchRelatedCourses = async (query: string, limit?: number, orderBy?: string) => {
  try {
    let qs = `?${query}&orderBy=${orderBy || 'registrations'}`;
    if (limit) {
      qs += `&limit=${limit}`;
    }
    const response = await fetch(`${API_ENDPOINTS.FETCH_TOP_COURSES}${qs}`, {
      method: API_METHODS.GET,
      headers: {
        ...DEFAULT_API_HEADER,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    if (!response.ok) {
      return {
        error: await response.clone().text(),
      };
    } else {
      const { courses } = await response.json();
      return { courses };
    }
  } catch (err) {
    return { error: err.message };
  }
};

export const fetchCourseDetails = async (id: string) => {
  try {
    const response = await fetch(`${API_ENDPOINTS.FETCH_TOP_COURSES}/${id}`, {
      method: API_METHODS.GET,
      headers: {
        ...DEFAULT_API_HEADER,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    if (!response.ok) {
      return {
        error: await response.clone().text(),
      };
    } else {
      const { modules, isRegistered, quiz }  = await response.json();
      return { modules, isRegistered, quiz };
    }
  } catch (err) {
    return { error: err.message };
  }
};

export const enterCourse = async (id: string, cardId?: string, couponId?: string) => {
  try {
    const response = await fetch(`${API_ENDPOINTS.FETCH_TOP_COURSES}/${id}/register`, {
      method: API_METHODS.POST,
      headers: {
        ...DEFAULT_API_HEADER,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({ cardId: cardId || '', coupon_id: couponId }),
    });
    if (!response.ok) {
      return {
        error: await response.clone().text(),
      };
    } else {
      const { modules, registration }  = await response.json();
      return { modules, registration };
    }
  } catch (err) {
    return { error: err.message };
  }
};

export const completeCourse = async (id: string) => {
  try {
    const response = await fetch(`${API_ENDPOINTS.FETCH_TOP_COURSES}/${id}/complete`, {
      method: API_METHODS.POST,
      headers: {
        ...DEFAULT_API_HEADER,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    if (!response.ok) {
      return {
        error: await response.clone().text(),
      };
    } else {
      const { success }  = await response.json();
      return { success };
    }
  } catch (err) {
    return { error: err.message };
  }
};

export const getNextLesson = async (id: string) => {
  try {
    const response = await fetch(`${API_ENDPOINTS.FETCH_TOP_COURSES}/${id}/proceed`, {
      method: API_METHODS.POST,
      headers: {
        ...DEFAULT_API_HEADER,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    if (!response.ok) {
      return {
        error: await response.clone().text(),
      };
    } else {
      const { success }  = await response.json();
      return { success };
    }
  } catch (err) {
    return { error: err.message };
  }
};
